import React from 'react';
import { useNavigate } from 'react-router-dom';

const navigateToServices = (navigate: any) => {
    navigate('/#services'); // This navigates to the main page with the hash
  };

// Function to handle smooth scrolling to the "Contact" section
const scrollToContact = () => {
    const contactSection = document.getElementById("contact"); // Ensure the Contact section has this id
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
const FAQ = () => {
    const navigate = useNavigate();

    return (
    <div id='FAQ' className="bg-[#f4f1e9] pb-24 px-0 scroll-pt-6 scroll-mt-16">
        {/* Hero Section */}
        <div className="relative h-[250px] md:h-[500px]">
            <img
                src="assets/arch-with-hanging-pampas-decor-picnic-background-datesaverco-dmv.webp"
                alt="For You Picnic"
                className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex w-full">
                <h1 className="text-white font-the-seasons text-lg md:text-6xl md:text-left text-center flex items-end pl-5 pb-24 md:items-center md:pl-16 md:pt-44">
                FREQUENTLY ASKED QUESTIONS
                </h1>
            </div>
            
        </div>    
        {/* Text Section, left col */}
        <div className="md:max-w-full md:mx-auto mx-11 grid grid-cols-1 lg:grid-cols-2 ">
            <div className="font-columbia-serial space-y-8 text-[#342b29]  justify-center items-center text-left md:items-start md:pl-[5%] lg:pl-[30%] md:flex-col">

                    <h2 className="text-4xl font-the-seasons pt-20">YOUR QUESTIONS, <br /> ANSWERED</h2>

                    <div className="text-2xl font-normal md:pl-44">GENERAL BOOKING</div>

                    <div className="font-columbia-serial">
                        <h3 className="text-lg font-semibold">WHICH MARKETS DO YOU SERVE?</h3>
                        <p className="">
                            We serve DC, Southern Maryland, and Northern Virginia for parties, weddings, corporate events, and more. A travel fee may apply beyond 15 miles. 
                            Message us to bring your plans to life!
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">HOW DO I RESERVE?</h3>
                        <p className="">
                            Your date is secured as soon as you’ve paid the <span className='font-semibold'>retainer</span> and signed<br/> 
                            the <span className='font-semibold'>contract.</span> If plans change, no worries! We’ll happily refund your<br/>
                            fee if you cancel not more than 2 days from booking.
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">WHAT IS THE RETAINER FEE FOR?</h3>
                        <p className="">
                            To reserve your special date with Date Saver Co., we require a<br/> 
                            retainer fee. This fee secures your date and allows us to plan ahead,<br/>
                            ensuring everything runs smoothly. We're here to make your<br/>
                            experience unforgettable, and this step helps us do just that!
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">HOW FAR IN ADVANCE SHOULD I BOOK?</h3>
                        <p className="">
                            Generally, bookings must be made at least 48 hours in advance. We<br/> 
                            cannot accept bookings with less than 48 hours' notice. To secure<br/>
                            your preferred date and time, it’s best to book as early as possible, as<br/>
                            availability may be limited.
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">WHEN DO BOOKINGS OPEN?</h3>
                        <p className="">
                            We release our bookings seasonally! This means you can plan ahead<br/> 
                            and secure your event months in advance. Right now, bookings are<br/>
                            open until the <span className='font-semibold'>end of spring 2025</span>, with new slots becoming available<br/>
                            availability may be limited.
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">HOW MANY SPOTS ARE AVAILABLE PER MONTH?</h3>
                        <p className="">
                            To provide the best experience for each client, we limit our availability<br/> 
                            to just <span className='font-semibold'>4 bookings per month across all services</span>. These spots tend to<br/>
                            fill up quickly, so we highly recommend booking early!
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">CAN I BOOK BEYOND THE CURRENT SEASON?</h3>
                        <p className="">
                            Bookings for future seasons will be announced as we approach the<br/> 
                            end of the current booking period. Stay tuned for our summer and fall
                            dates!
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">WHEN DOES THE CURRENT BOOKING PERIOD END?</h3>
                        <p className="">
                            Our current booking period runs through the end of SPRING 2025, so<br/> 
                            you can reserve dates up until then. If you’re looking to plan ahead,<br/>
                            now’s the perfect time!
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">HOW CAN I BE FIRST IN LINE FOR NEW OPENINGS?</h3>
                        <p className="">
                            Sign up <span className='underline'>HERE</span> for our seasonal booking alerts to get first access when<br/> 
                            new dates are released. Spots go quickly, so being on our email list is<br/>
                            the best way to secure your preferred date!
                        </p>
                    </div>

                    <div className="text-2xl font-normal md:pl-20">RESCHEDULING / CANCELLATIONS</div>

                    <div className="">
                        <h3 className="text-lg font-semibold">CANCELLATIONS AND RESCHEDULING</h3>
                        <p className="">
                            Please note that our retainer fee is non-refundable due to the time<br/> 
                            and effort we invest in planning and preparation. However, we’re<br/>
                            more than happy to help you reschedule your event to a later date<br/>
                            that suits you better. Our goal is to make sure you have a memorable<br/>
                            experience, and we’re here to assist in any way we can!
                        </p>
                    </div>

                    <div className="text-2xl font-normal md:pl-52">WEATHER</div>

                    <div className="">
                        <h3 className="text-lg font-semibold">WHAT IF THE WEATHER DOES NOT COOPERATE?</h3>
                        <p className="">
                            If the weather doesn’t cooperate, we’ll reschedule to a date that<br/> 
                            works for you. Add <span className='underline'>weather insurance for $95 at checkout</span> to cancel<br/>
                            last minute due to weather and get a full refund, including your<br/>
                            retainer. Note: the $95 insurance fee is non-refundable.
                        </p>
                    </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">WEATHER INSURANCE</h3>
                        <p className="">
                            For $95, our weather insurance lets you cancel last minute due to bad<br/> 
                            weather and get a full refund, including your retainer. The $95 fee is<br/>
                            non-refundable.
                        </p>
                    </div>
            </div>
        

            {/*Image for right col */}
            <div className="md:flex-col mx-auto md:mx-20 md:items-center md:text-right lg:text-left font-columbia-serial space-y-8">
                <div className='overflow-hidden rounded-[60px] md:pl-16 pt-11'>
                    <img
                        src="/assets/faq-two-knit-chairs-with-umbrella-between-grazed-by-the-golden-hour-sunlight-date-saver-co-picnic-DMV.webp"
                        alt="Outdoor scene"
                        className="relative rounded-[30px] md:rounded-[60px] w-full md:max-w-sm max-w-xs h-auto "
                    />
                </div>
            {/* Text Section, right col */}
                <div className="text-2xl font-normal text-center max-w-lg object-left items-start pt-8">PICNIC / LAZY BOY</div>

                <div className="">
                        <h3 className="text-lg font-semibold">HOW EARLY WILL YOU SET UP FOR MY APPOINTMENT?</h3>
                        <p className="">
                            We usually start setting up about 2 hours before your event, or a bit<br/> 
                            longer for larger guest lists. To ensure everything is perfectly ready<br/>
                            and you get the full Date Saver Co. experience, we kindly ask that you<br/>
                            arrive closer to the scheduled time rather than early. 
                        </p>
                </div>

                    <div className="">
                        <h3 className="text-lg font-semibold">HOW MANY GUESTS CAN YOU ACCOMODATE AT THE TABLE?</h3>
                        <p className="">
                            Right now, we can accommodate up to 4 guests for our picnics and<br/> 
                            Lazy Boy high tables. As we continue to grow, we plan to expand this<br/>
                            number to host more guests.
                        </p>
                    </div>

                {/* First Button */}
                <div className="md:pl-44">
                    <button 
                    onClick={() => navigateToServices(navigate)} // Navigate to #services
                    className="font-columbia-serial text-[#f4f1e9] text-xs sm:text-sm rounded-lg border border-[#342b29] px-6 sm:px-9 py-3 bg-[#342b29] hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid">
                        BOOK NOW
                    </button>
                </div>

                <div className="text-2xl font-normal text-center max-w-lg object-left items-start pt-8">EVENT CONTENT CREATOR</div>

                <div className="">
                        <h3 className="text-lg font-semibold">WHAT THE EVENT CONTENT CREATOR SERVICE?</h3>
                        <p className="">
                            Introducing a NEW way to capture your event, focusing on natural,<br/> 
                            candid moments—just like it was filmed from a guest’s perspective. A<br/>
                            professional DMV-based content creator will record your event using<br/>
                            an <span className='font-semibold'>iPhone</span>. You’ll receive both raw, unedited footage and edited<br/>
                            highlights in a "short-style" format, perfect for easy viewing and<br/>
                            sharing. For more details about our content creator, feel free to<br/>
                            message us!
                        </p>
                </div>

                <div className="">
                        <h3 className="text-lg font-semibold">HOW WILL THE CONTENT BE RECORDED?</h3>
                        <p className="">
                            We’ll use an <span className='font-semibold'>iPhone</span> to capture your event, along with a <span className='font-semibold'>separate<br/> 
                            microphone</span> for high-quality audio. Filming this on a phone aims to<br/>
                            focus on natural, candid stories that truly reflect the essence of your<br/>
                            experience.
                        </p>
                </div>

                <div className="">
                        <h3 className="text-lg font-semibold">CAN I REQUEST SPECIFIC SHOTS TO BE CAPTURED?</h3>
                        <p className="">
                            Absolutely! You can list all your shot requests in the intake form. We’ll<br/> 
                            review your details and get back to you with a quote.
                        </p>
                </div>

                <div className="">
                        <h3 className="text-lg font-semibold">WHEN WILL I RECEIVE THE CONTENT AFTER THE EVENT?</h3>
                        <p className="">
                            You’ll receive your content within 1-2 weeks after the event,<br/> 
                            depending on add-ons and the event’s length.
                        </p>
                </div>

                <div className="">
                        <h3 className="text-lg font-semibold">CAN I USE THE CONTENT ON SOCIAL MEDIA?</h3>
                        <p className="">
                            Absolutely! We’d appreciate it if you could tag both the content<br/> 
                            creator and Date Saver Co. in your posts. It helps us share the great<br/>
                            moments and support our work. Thanks so much!
                        </p>
                </div>

                <div className="">
                        <h3 className="text-lg font-semibold">IS THERE AN OPTION FOR ADDITIONAL EDITS?</h3>
                        <p className="">
                            Yes, if you have specific edits or inspiration in mind, let us know! We’ll<br/> 
                            work with the content creator to see what can be accommodated<br/>
                            based on their availability and agreement.
                        </p>
                </div>
                {/*Last text w/ 'hollow bullets' */}
                <div className="">
                        <h3 className="text-lg font-semibold">HOW TO BOOK AN EVENT CONTENT CREATOR:</h3>
                        <h2 className="md:items-start md:pl-[4%] lg:pl-[2%]">1. <span className='text-base font-medium'>Submit Intake Form</span></h2>
                        <p className="md:items-start md:pl-[5%] lg:pl-[5.25%]">
                            <span className="mr-2 text-lg">○</span>Fill out the intake form with details about your event and any<br/>
                            special content requests you may have.
                        </p>

                        <h2 className="md:items-start md:pl-[4%] lg:pl-[2%]">2. <span className='text-base font-medium'>Receive Custom Quote</span></h2>
                        <p className="md:items-start md:pl-[5%] lg:pl-[5.25%]">
                            <span className="mr-2 text-lg">○</span>We’ll provide a custom quote based on your information,<br/>
                            including the base price and any additional costs. We’ll also<br/>
                            send you an invoice for your review.
                        </p>
                        
                        <h2 className="md:items-start md:pl-[4%] lg:pl-[2%]">3. <span className='text-base font-medium'>Confirm and Pay</span></h2>
                        <p className="md:items-start md:pl-[5%] lg:pl-[5.25%]">
                            <span className="mr-2 text-lg">○</span>When you’re ready to proceed, secure your date by paying<br/>
                            the retainer fee via the invoice. We’ll then send you a booking<br/>
                            confirmation with all the details.
                        </p>
                </div>

                {/* First Button */}
                <div className="md:pl-44">
                    <button 
                    onClick={() => navigateToServices(navigate)} // Navigate to #services
                    className="font-columbia-serial  text-[#f4f1e9] text-xs sm:text-sm rounded-lg border border-[#342b29] px-6 sm:px-9 py-3 bg-[#342b29] hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid">
                        BOOK NOW
                    </button>
                </div>
            </div>        
        </div>
    </div>
    );
}

export default FAQ;