import React from 'react';

const Moments = () => {
    return (
        <div className="bg-[#f4f1e9] py-0 md:py-10 px-4 sm:px-6 md:px-20 lg:px-40 text-center">
            <div className="w-full max-w-lg sm:max-w-xl md:max-w-4xl lg:max-w-6xl mx-auto mt-10 mb-20">
                <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-[#51423e] leading-snug mb-6 sm:mb-8 md:mb-10 font-columbia-serial">
                    Let’s make moments that are too <span className="text-[#7a6d4e]">real</span> for the highlight <span className="text-[#7a6d4e]">reel</span>.
                </h1>
                <div className="w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[450px] shadow-md rounded-lg overflow-hidden">
                    <img 
                        src="/assets/girlfriend-plays-conversation-card-game-with-fiance-while-having-picnic-datesaverco-DMV.webp" 
                        alt="Girlfriend playing with conversation card game with her fiance while having a picnic." 
                        className="w-full h-full object-cover object-center transform scale-125 sm:scale-100"
                    />
                </div>
            </div>
        </div>
    );
}

export default Moments;
