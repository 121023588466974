import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {

    // Scroll to top function
    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div id='services' className='bg-[#f4f1e9] w-full h-full pt-0 pb-16'>
            <h2 className="text-2xl sm:text-3xl md:text-4xl text-[#51423e] mt-12 pt-12 sm:mt-10 font-bold font-the-seasons text-center">
                <span className="text-[#7a6d4e]"> OUR </span> SERVICES
            </h2>
            <div className="w-16 sm:w-24 h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-50"></div>

            {/* Images Section */}
            <div className="gap-6 mt-6 sm:mt-12 max-w-[75%] mx-auto text-center font-the-seasons text-[#342b29] px-4 flex flex-wrap justify-center items-center">

                {/* First Image */}
                <Link to="/fyp" onClick={handleLinkClick}>
                    <div className="rounded-2xl relative flex h-auto max-w-md shadow-lg group overflow-hidden">
                        <div className="absolute flex-col flex inset-0 bg-black bg-opacity-10 justify-center items-center z-10 ">
                            <h2 className='text-6xl md:text-8xl text-white font-normal'>FYP</h2>
                            <h2 className='text-md md:text-lg text-white font-normal'>For You Picnic</h2>
                        </div>
                        <img
                            src="/assets/fyp-date-saver-co-DMV.webp"
                            alt="FYP for you picnic table scape sunflower arrangement"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                </Link>

                {/* Second Image */}
                <Link to="/lazyboy" onClick={handleLinkClick}>
                    <div className="rounded-2xl relative flex h-auto max-w-md shadow-lg group overflow-hidden">
                        <div className="absolute flex flex-col inset-0 bg-black bg-opacity-10 justify-center items-center z-10">
                            <h2 className='text-6xl md:text-8xl text-white font-normal'>Lazyboy</h2>
                            <h2 className='text-md md:text-lg text-white font-normal'>Tablescape</h2>
                        </div>
                        <img
                            src="assets/lazyboy-tablescape-DMV-date-saver-co.webp"
                            alt="lazy boy home date night man pours sparkling wine into flute-glass"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                </Link>

                {/* Third Image */}
                <Link to="/contentcreator" onClick={handleLinkClick}>
                    <div className="rounded-2xl relative flex h-auto max-w-md shadow-lg group overflow-hidden">
                        <div className="absolute inset-0 bg-black bg-opacity-10 flex justify-center items-center z-10">
                            <h2 className='text-5xl md:text-6xl text-white font-normal'>Event Content Creator</h2>
                        </div>
                        <img
                            src="/assets/event-content-creator-DMV-date-saver-co.webp"
                            alt="content creator for hire"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                </Link>

                {/* Second Portion */}
                <h1 className='font-the-seasons text-center w-full mt-14 md:mt-10 text-2xl sm:text-3xl md:text-4xl font-normal'>RESERVE NOW FOR 2025</h1>

                {/* Fourth Image */}
                <Link to="/photobooth" onClick={handleLinkClick}>
                    <div className="rounded-2xl relative flex h-auto max-w-md shadow-lg group overflow-hidden">
                        <div className="absolute inset-0 bg-black bg-opacity-10 flex justify-center items-center z-10">
                            <h2 className='text-5xl md:text-6xl text-white font-normal'>Glam Photobooth</h2>
                        </div>
                        <img
                            src="assets/retro-mirror-photobooth-DMV-date-saver-co.webp"
                            alt="couple laughing"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                </Link>         

                {/* Fifth Image */}
                {/* <Link to="/stylizedproposal" onClick={handleLinkClick}>
                    <div className="rounded-2xl relative flex h-auto max-w-md shadow-lg group overflow-hidden">
                        <div className="absolute inset-0 bg-black bg-opacity-10 flex justify-center items-center z-10">
                            <h2 className='text-5xl md:text-6xl text-white font-normal'>Stylized Proposal</h2>
                        </div>
                        <img
                            src="assets/washington-DC-surprise-proposal-DMV-date-saver-co.webp"
                            alt="stylized proposal pampas decor"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110"
                            loading="lazy"
                        />
                    </div>
                </Link>                        */}
            </div>
        </div>

        


    );
}

export default Services;
