import React, { useState } from 'react';
import { GiCheckMark } from "react-icons/gi";
import { FiChevronDown } from 'react-icons/fi';

import { TfiArrowCircleRight } from "react-icons/tfi";
import { TfiArrowCircleLeft } from "react-icons/tfi";



const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === children.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? children.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative w-full overflow-hidden rounded-3xl">
      <div
        className="flex transition-transform ease-in-out duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {children.map((child, index) => (
          <div key={index} className="min-w-full">
            {child}
          </div>
        ))}
      </div>

      {/* Left and Right Navigation Buttons */}
      <button
        className="text-white absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg"
        onClick={prevSlide}
      >
        <TfiArrowCircleLeft size={30} />
      </button>
      <button
        className="text-white bg-transparent absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg"
        onClick={nextSlide}
      >
        <TfiArrowCircleRight   size={30} />
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {children.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full bg-white transition-all ${
              currentSlide === index ? 'p-2' : 'bg-opacity-50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const ContentCreator = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedImage, setSelectedImage] = useState(''); // Image state for modal

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to open modal with the selected image
  const openModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  const scrollTo= () => {
    const contactSection = document.getElementById("bookhere"); 
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className='bg-[#f4f1e9]'>
      {/* Hero Section */}
      <div className="relative h-[200px] md:h-[350px]">
        <img
          src="assets/content-creator/hero-2-content-creator-wedding-DMV-date-saver-co.webp"
          alt="Content creator filming weddings for Date Saver Co"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-10 flex w-full">
          <h1 className="text-white font-the-seasons text-md md:text-4xl md:text-left text-center flex items-end pl-5 pb-16 md:items-center md:pl-16 md:pt-44">
            Event Content Creator – capturing your real with our reels
          </h1>
        </div>
      </div>

      {/* What's Included Section */}
      <div className="py-8 px-4 md:py-12 md:px-16 lg:px-20 mx-auto max-w-[85%] md:flex md:items-center">
        {/* Left Column - Text Section */}
        <div className="md:max-w-md md:mx-auto">
          <h3 className="text-3xl md:text-4xl font-bold font-the-seasons mb-6 text-center">
            What is an Event Content Creator?
          </h3>
          <p className="text-md leading-relaxed font-columbia-serial text-[#231f20] mb-6 text-justify md:max-w-[80%] justify-center md:mx-auto">
          Our DMV content creator captures real, raw moments, so you can be present without worrying about missing out. Using an iPhone, 
          we record key highlights and guest interactions, preserving candid memories while you fully enjoy your event. No FOMO here.
          </p>
          <div className='items-center justify-center mx-auto max-w-[40%] md:pl-6 pb-11 md:pb-0'>
            <button onClick={scrollTo} className="font-the-seasons px-5 py-3 bg-transparent border border-black text-black font-semibold hover:bg-[#231f20] hover:text-white transition duration-300 rounded">
              Book Now
            </button>
          </div>
        </div>

        {/* Right Column - Image Section */}
        <div className="md:w-[60%] md:mt-8">
          <img
            src="assets/content-creator/PRICING-Event-Content-Creator.webp"
            alt="Event Content Creator Packages"
            className="w-full h-auto object-cover shadow-lg rounded-lg cursor-pointer drop-shadow-lg shadow-red-950 transition duration-300"
            onClick={() => openModal("assets/content-creator/PRICING-Event-Content-Creator.webp")} // Open modal on click
          />
        </div>
      </div>

      {/* Modal for Enlarged Image */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
          <div className="relative">
            <img
              src={selectedImage}
              alt="Enlarged Event Content Creator"
              className="max-w-[90vw] max-h-[90vh] rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}

      {/* Get Started Section */}
      <div className="py-10 px-4 md:pt-28 md:pb-16 md:mx-auto md:max-w-6xl text-justify md-px-11">
        <h2 className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-8 md:mb-12 text-center font-bold">
          GET STARTED
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-11 gap-7">
          {/* Step 1 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-semibold font-the-seasons mb-4 text-center">
              Step 1 <span className='font-sans font-extralight'>|</span> Inquiry Form
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              Reach out to our content creator with details about your event with an <span className='font-bold'>Inquiry Form</span>.
            </p>
          </div>
          {/* Step 2 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4 text-center">
              Step 2 <span className='font-sans font-extralight'>|</span> Consult
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              We’ll get back to you via email with our <span className='font-bold'>availability</span>, a <span className='font-bold'>quote</span>, <span className='font-bold'>answers</span> to your questions, and a <span className='font-bold'>consult</span>. <br/><span className='italic'><span className='font-bold'>Please note</span> that availability is subject to confirmation, even if a date is selected on the scheduling form.</span>        
            </p>
          </div>
          {/* Step 3 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4 text-center">
              Step 3 <span className='font-sans font-extralight'>|</span> Confirm and Book
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              Secure your date with the <span className='font-bold'>retainer</span>. Plus, get a 2nd consult with the event content creator a week before the event for any final adjustments--ensuring everything is in order. And you’re all set!            </p>
          </div>
        </div>
      </div>

      {/* Acuity Scheduling Section */}
      <div className="py-5 md:max-w-[80%] md:pb-1 md:pt-11 md:mx-auto text-left">
        <h2 id='bookhere' className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-4 md:mb-14 text-center font-bold">
          BOOK HERE
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-0">
          {/* Text and Image Section */}
          <div className="text-[#231f20]">
            <h3 className="text-lg md:text-3xl font-bold font-the-seasons mb-4 text-center">
              Event Content Creator
            </h3>
            <p className='text-center mx-auto max-w-xl'>Please reach out to us by phone, email or the form below if you have any questions before booking. </p>
            <div className='w-[80%] mx-auto h-auto object-cover mt-6 rounded-xl shadow-lg'>
            <Carousel>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/content-creator/carousel-content-creator.webp"
                alt="Picnic wide angle"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/content-creator/event-content-creator-DMV-date-saver-co.webp"
                alt="Content Creator Samp"
                className="object-contain w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/content-creator/carousel-1-content-creator-wedding-DMV-date-saver-co.webp"
                alt="Content Creator Wedding Event"
                className="object-cover w-full h-full"
              />
            </div>
          </Carousel>
          </div>

            {/* Dropdown Section */}
            <div className='py-7'>
              <h4
                className="underline text-xl md:text-3xl font-bold font-the-seasons cursor-pointer flex items-center pl-5 md:pl-9 justify-center"
                onClick={toggleDropdown}
              >
                Important Notes:&nbsp;
                <FiChevronDown
                  className={`transition-transform transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                  size={25}
                />
              </h4>

              {/* Dropdown content */}
              <div
                className={`md:pl-0 overflow-hidden transition-all duration-500 ${isDropdownOpen ? 'max-h-screen' : 'max-h-0'}`}
              >
                <ul className='list-disc mx-7 md:mx-auto md:max-w-xl pt-5 space-y-3 text-justify text-sm'>
                  <li>
                    <span className='underline font-bold'>Rain / Strong Winds:</span> To keep our equipment safe, we may need to withhold items 
                    like tech, tables, and pillows in certain weather conditions. If the customer chooses to continue, they will be responsible for any damages. 
                    For safety, rain or strong winds may prevent us from setting up specific items. Thank you for your understanding!
                  </li>
                  <li>
                    <span className='underline font-bold'>Set-up Time:</span> Please allow our content creator 30-60 min to set up and get familiar with the environment before the event.
                  </li>
                  <li>
                    <span className='underline font-bold'>Retainer Fee: </span> To reserve your special date, we require a non-refundable retainer fee of 50% of the total cost. 
                    This fee secures your date and allows us to plan ahead, ensuring everything runs smoothly. If you need to cancel, we'll refund the fee if it's within two days 
                    after booking. We're here to make your experience unforgettable, and this step helps us do just that!
                  </li>
                  <li>
                    <span className='underline font-bold'>Cancellations:</span> Bookings made or canceled less than 48 hours before the event are non-refundable.

                  </li>
                  <li>
                    <span className='underline font-bold'>Rescheduling:</span> Unexpected things happen! Let us know if you need to reschedule, 
                    and we’ll work with you to find a new time, subject to availability.
                  </li>
                  <li>
                    <span className='underline font-bold'>Weather Insurance ($95):</span>  Add weather insurance for $95 to your booking for
                     peace of mind. If bad weather strikes, you can cancel up to the last minute and get a full refund, including the retainer. 
                     You can select this option at checkout. (Non-refundable).
                  </li>
                  <li>
                    <span className='underline font-bold'>Location access:</span> It is the client’s responsibility to ensure that <span className='font-bold'>private</span> locations
                     are accessible for setup and takedown at the agreed-upon times. 
                  </li>
                  <li>
                    <span className='underline font-bold'>Last minute time extension:</span> If there are delays, and you would wish to extend your time, you may do so at the 
                    event (just give us a call or approach any of our team members). Appointment time extensions are subject to availability and charges specific to the service.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Acuity Scheduling iframe */}
          <div className="mb-20 w-full overflow-hidden rounded-xl">
            <p className='text-center md:pb-9'> Please scroll down to see more information. </p>
            <iframe
              src="https://app.acuityscheduling.com/schedule.php?owner=33469695&owner=33469695&appointmentType=category:Event%20Content%20Creator"
              title="Schedule Appointment"
              className="w-full h-[1800px] md:h-[900px] rounded-xl"
              style={{ border: 'none' }}
            >
              <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentCreator;
