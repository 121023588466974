import React, { useState, FormEvent } from "react";

const subscribeForm = "https://dashboard.mailerlite.com/forms/1020808/126536398884832508/share";

// Function to handle email subscription
const findRequestURL = (mail: string): string => {
  const formURL = subscribeForm.split("/");
  const getNumbers = formURL.filter((item) => Number(item) && item);
  const accountID = getNumbers[0];
  const formID = getNumbers[1];
  const requestURL = `https://assets.mailerlite.com/jsonp/${accountID}/forms/${formID}/subscribe?fields[email]=${mail}`;
  return requestURL;
};

interface HeroProps {
  heading: string;
  message: string;
}

const Hero: React.FC<HeroProps> = ({ heading, message }) => {
  const [mail, setMail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [apiMessage, setApiMessage] = useState<string>("");

  // Handle email subscription
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    fetch(findRequestURL(mail), {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setApiMessage("Thanks for subscribing!");
        } else {
          setApiMessage("Something went wrong, please try again.");
        }
      })
      .catch(() => setApiMessage("Something went wrong, please try again."))
      .finally(() => {
        setLoading(false);
        setMail("");
      });
  };

  // Function to handle smooth scrolling to the "Contact" section
  const scrollToContact = () => {
    const contactSection = document.getElementById("services");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen relative">
      <img
        src="/assets/hero-picnic-with-table-setting-sunflower-under-tree-shade-datesaverco-dmv-transformed.webp"
        alt="Background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-20 z-10" />
      
      {/* Text Content */}
      <div className="flex flex-col items-center justify-center p-5 text-center text-white relative z-20 max-w-full pt-24">
        {/* Logo */}
        <div className="max-w-full sm:max-w-[500px] md:max-w-[1000px] mx-auto mb-4">
          <img
            src="/assets/cream-hero-logo.png"
            alt="Hero Logo"
            className="w-full h-auto object-contain"
          />
        </div>
        
        {/* Heading and Message */}
        <p className="font-the-seasons font-bold text-xl sm:text-4xl tracking-wide text-white">
          Unplugged and Unflitered
        </p>
        <p className="font-columbia-serial text-xs sm:text-lg pt-3">
          DC <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          MD <span className="mx-3 sm:mx-6" style={{ fontFamily: "Arial" }}>|</span>
          NOVA
        </p>
        
        {/* Email Subscription Form */}
        <form onSubmit={onSubmit} className="mt-4 pt-12 sm:pt-24 relative flex flex-col items-center w-full">
        <div className="relative flex items-center justify-center w-full md:max-w-2xl md:mx-auto flex-col sm:flex-row">
          {/* Input field stays centered */}
          <input
            type="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Enter Your Email"
            className={`font-columbia-serial text-lg rounded-full px-0 py-1 text-white bg-white bg-opacity-15 placeholder-white text-center w-full sm:w-[80%] md:w-[80%] ${
              mail ? "bg-gray-100 bg-opacity-50" : ""
            }`}
            required
          />
          {/* Button aligned to the right of the input */}
          <button
            type="submit"
            className="md:absolute md:-right-20 rounded-md font-the-seasons md:px-4 px-4 md:py-2 py-2 mt-5 md:mt-0 mb-5 md:mb-0 bg-[#342b29] text-white md:text-lg text-md hover:bg-white hover:text-black transition border-solid"
            disabled={loading}
          >
            {loading ? "Loading..." : "Get Notified"}
          </button>
        </div>
          {/* Displaying message below the Save My Date button */}
          {apiMessage && <p className="mt-2 text-[#ffde59] text-sm">{apiMessage}</p>}
        <p className="font-columbia-serial text-white text-md sm:text-lg mb-4 px-2 italic max-w-lg md:pt-5">
          Be among the first 30 to sign up for email notifications and get a chance to win a FREE picnic!
        </p>
        </form>

        <button
          className="rounded-md max-w-48 mt-4 md:py-1 md:px-7 py-1 md:max-w-full font-the-seasons border border-[#f1f1ef] bg-transparent text-white md:text-lg text-md hover:bg-white hover:text-black transition border-solid w-[80%] sm:w-auto tracking-widest"
          onClick={scrollToContact}
        >
          BOOK NOW
        </button>
      </div>
    </div>
  );
};

export default Hero;