import React, { useState } from 'react';
import { TfiArrowCircleRight } from "react-icons/tfi";
import { TfiArrowCircleLeft } from "react-icons/tfi";

const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === children.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? children.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative w-full overflow-hidden rounded-3xl z-10"> {/* Added z-10 to ensure the carousel is on top */}
      <div
        className="flex transition-transform ease-in-out duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {children.map((child, index) => (
          <div key={index} className="min-w-full">
            {child}
          </div>
        ))}
      </div>

      {/* Left and Right Navigation Buttons */}
      <button
        className="text-white absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg z-20" // Ensure the buttons have higher z-index
        onClick={prevSlide}
      >
        <TfiArrowCircleLeft size={30} />
      </button>
      <button
        className="text-white bg-transparent absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg z-20" // Ensure the buttons have higher z-index
        onClick={nextSlide}
      >
        <TfiArrowCircleRight size={30} />
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2 z-20"> {/* z-20 to ensure indicators are on top */}
        {children.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full bg-white transition-all ${
              currentSlide === index ? 'p-2' : 'bg-opacity-50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const BestStories = () => {
  return (
    <div className="bg-[#f4f1e9] pt-10 pb-10 md:pt-12 md:pb-20 relative">
      <div className="max-w-[90%] mx-auto flex flex-col md:flex-row items-center relative md:pl-11">
        {/* Left Column: Carousel */}
        <div className="w-full md:max-w-[45%] flex flex-col items-center md:items-start md:pl-11">
          <Carousel>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/contactus-picnic-wide-angle-datesaverco-DMV-1.webp"
                alt="Picnic wide angle"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/dried-pampas-floral-hanging-decor-on-teepee-date-saver-co-dmv.webp"
                alt="Dried pampas floral hanging decor"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-belly-laughing-on-knit-lounge-chairs-date-saver-co-picnic-DMV.webp"
                alt="Couple belly laughing on knit lounge chairs"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-smiling-admiring-sparkling-wine-sitting-by-low-picnic-table-date-saver-co-picnic-DMV.webp"
                alt="Couple smiling admiring sparkling wine"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/final-couple-smiling-looking-at-INSTAX-photo-while-sitting-on-a-mat-illuminated-by-white-teepee-fabric-datesaverco.webp"
                alt="Couple smiling looking at INSTAX"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-56 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/hero-picnic-with-table-setting-sunflower-under-tree-shade-datesaverco-dmv-transformed.webp"
                alt="Hero picnic with table setting"
                className="object-cover w-full h-full"
              />
            </div>
          </Carousel>
        </div>

        {/* Right Column: Text Content */}
        <div className="w-full md:max-w-[40%] mx-auto flex flex-col justify-center items-center md:items-start text-center md:pt-0 pt-10 relative md:text-left pb-11 md:mt-0 z-10">
          <img
            src="/assets/brownlogo.png"
            alt="Brown Date Saver Co logo"
            className="h-auto w-24 md:w-36 mb-4 md:relative md:right-11"
            loading="lazy"
          />
          <h2 className="text-sm font-the-seasons uppercase text-[#98876d] tracking-wide mb-2">
            DATE SAVER CO
          </h2>
          <p className="text-2xl md:text-5xl font-columbia-serial text-[#51423e] leading-tight">
            Where the best stories aren't posted—<br />
            <br />
            they're lived.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BestStories;
