import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Optional: for setting the page title and meta tags
import Hero from './components/Hero/Hero';
import Contact from './components/Contact/Contact';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Moments from './components/Moments/Moments';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import BestStories from './components/BestStories/BestStories';
import NavBar from './components/NavigationBar/NavBar';
import Services from './components/Services/Services';
import FAQ from './components/FAQ/FAQ';
import FYP from './components/FYP/FYP';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import LazyBoy from './components/LazyBoy/LazyBoy';
import ContentCreator from './components/ContentCreator/ContentCreator';
import Photobooth from './components/Photobooth/Photobooth';
import './styles/globals.css';

const App: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Date Saver Co</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Router>
        <Routes>
          {/* Layout with NavBar */}
          <Route 
            path="/" 
            element={
              <>
                <NavBar /> {/* NavBar can now be conditionally rendered */}
                <Hero heading="date saver co" message="Upscale Picnics, Event Content Creation, Tablescapes, and Photobooth services in the DMV, DC | Maryland | Virginia. Discover how we can help you unplug and celebrate with ease!" />
                <Services />
                <WhatWeDo />
                <Moments />
                <WhoWeAre />
                <BestStories />
                <Contact /> {/* Contact at the bottom of every page */}
              </>
            } 
          />
          
          {/* Additional Routes */}
          <Route path="/fyp" element={<><NavBar /><FYP /><Contact /></>} />
          <Route path="/contentcreator" element={<><NavBar /><ContentCreator /><Contact /></>} />
          <Route path="/lazyboy" element={<><NavBar /><LazyBoy /><Contact /></>} />
          <Route path="/termsandconditions" element={<><NavBar /><TermsAndConditions /><Contact /></>} />
          <Route path="/photobooth" element={<><NavBar /><Photobooth /><Contact /></>} />
          <Route path="/FAQ" element={<><NavBar /><FAQ /><Contact /></>} />
          {/* 404 Page */}
          <Route path="*" element={<h1 className='text-4xl text-center mt-20'>404 - Page Not Found <br/><br/> Please head back to datesaverco.com </h1>} />
        </Routes>
      </Router>
    </>
  );
};

export default App;