import React from 'react';

const WhatWeDo = () => {

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        };

    return (
        <div className="bg-[#f4f1e9] w-full h-full pt-8 pb-10">
            <div className="relative w-full mx-auto">
                {/* Background Shape */}
                <div className="bg-[#e1d9d2] w-[75%] sm:w-[80%] h-48 sm:h-64 flex rounded-r-[30px] sm:rounded-r-[50px] z-0 shadow-lg"></div>
                
                {/* Image */}
                <div className="flex justify-end -mt-36 sm:-mt-48 z-10">
                    <img 
                        src="/assets/whatwedo.jpg" 
                        alt="What We Do" 
                        className="w-[90%] sm:w-[70%] h-48 sm:h-64 md:h-80 object-cover rounded-l-xl shadow-lg"
                        loading="lazy"
                    />
                </div>
            </div>

            <h2 className="text-2xl sm:text-3xl md:text-4xl text-[#51423e] mt-12 pt-12 sm:mt-10 font-bold font-the-seasons text-center">
                <span className="text-[#7a6d4e]"> HOW </span> IT WORKS
            </h2>
            <div className="w-16 sm:w-24 h-[1px] bg-[#8d7467] mx-auto mt-2 opacity-50"></div>

            {/* 3 Columns Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 sm:mt-12 max-w-[75%] mx-auto text-center font-columbia-serial text-[#342b29] px-4">
                {/* Column 1 */}
                <div className="relative flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg group rounded-xl">
                        <img
                            src="/assets/we-prepare-man-decorates-teepee-with-hanging-pamapas-decoration-boho-decoration-cute-picnic-datesaverco-DMV-transformed.webp"
                            alt="Man decorates tent with cute/boho hanging pamapas decoration"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110 rounded-xl"
                            loading="lazy"
                        />
                        {/* Centered Title */}
                        <div className="absolute inset-0 flex items-center justify-center">
                            <h3 className="text-2xl sm:text-3xl text-[#f4f1e9] font-the-seasons font-semibold tracking-wide">We Prepare</h3>
                        </div>
                    </div>
                    <p className="mt-2 text-sm sm:text-base">
                        We handle all the setup, whether it's a picnic, photobooth, or capturing content, so you can focus on being present.<br /><br />
                        <span className='italic'>NO SOCIAL MEDIA STRESS HERE.</span>
                    </p>
                </div>

                {/* Column 2 */}
                <div className="relative flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg group rounded-xl">
                        <img
                            src="/assets/you-enjoy-couple-taking-an-INSTAX-picture-while-having-a-picnic-datesaverco-DMV.webp"
                            alt="Couple taking an instax polaroid while having a picnic with datesaverco"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110 rounded-xl"
                            loading="lazy"
                        />
                        {/* Centered Title */}
                        <div className="absolute inset-0 flex items-center justify-center">
                            <h3 className="text-2xl sm:text-3xl text-[#f4f1e9] font-the-seasons font-semibold tracking-wide">You Enjoy</h3>
                        </div>
                    </div>
                    <p className="mt-2 text-sm sm:text-base">
                        We leave the rest to you—the laughter, the connections so you don’t miss out. 
                    </p>
                </div>

                {/* Column 3 */}
                <div className="relative flex flex-col items-center">
                    <div className="relative w-full h-48 sm:h-56 md:h-64 overflow-hidden mb-4 shadow-lg group rounded-xl">
                        <img
                            src="/assets/we-wrap-up-picnic-basket-with-picnic-background-datesaverco-DMV-transformed.webp"
                            alt="Picnic basket with picnic background"
                            className="w-full h-full object-cover transition duration-300 ease-in-out transform group-hover:scale-110 rounded-xl"
                            loading="lazy"
                        />
                        {/* Centered Title */}
                        <div className="absolute inset-0 flex items-center justify-center">
                            <h3 className="text-2xl sm:text-3xl text-[#f4f1e9] font-the-seasons font-semibold tracking-wide">We Wrap Up</h3>
                        </div>
                    </div>
                    <p className="mt-2 text-sm sm:text-base">
                        After the fun, we handle the cleanup, send you the footage, leaving you with nothing but great memories—the laughter, the connections that aren’t staged but felt deeply, long after the day is done.
                    </p>
                </div>
            </div>
            
            {/* Centered Button */}
            <div className="flex justify-center mt-16">
                <button 
                onClick={() => scrollToSection("services")}
                className="font-the-seasons font-bold text-[#342b29] text-md md:text-md rounded-lg border border-[#342b29] px-6 sm:px-9 py-3 hover:bg-[#342b29] hover:text-white transition border-solid">
                    BOOK NOW
                </button>
            </div>
        </div>
    );
}

export default WhatWeDo;
