import React, { useState } from 'react';
import { GiCheckMark } from "react-icons/gi";
import { FiChevronDown } from 'react-icons/fi';
import { TfiArrowCircleRight } from "react-icons/tfi";
import { TfiArrowCircleLeft } from "react-icons/tfi";



const Carousel = ({ children }: { children: React.ReactNode[] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === children.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? children.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative w-full overflow-hidden rounded-3xl">
      <div
        className="flex transition-transform ease-in-out duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {children.map((child, index) => (
          <div key={index} className="min-w-full">
            {child}
          </div>
        ))}
      </div>

      {/* Left and Right Navigation Buttons */}
      <button
        className="text-white absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg"
        onClick={prevSlide}
      >
        <TfiArrowCircleLeft size={30} />
      </button>
      <button
        className="text-white bg-transparent absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full shadow-lg"
        onClick={nextSlide}
      >
        <TfiArrowCircleRight   size={30} />
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
        {children.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 rounded-full bg-white transition-all ${
              currentSlide === index ? 'p-2' : 'bg-opacity-50'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const Photobooth = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedImage, setSelectedImage] = useState(''); // Image state for modal

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to open modal with the selected image
  const openModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };


  const scrollTo= () => {
    const contactSection = document.getElementById("bookhere"); 
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };


  
  return (
    <div className='bg-[#f4f1e9]'>
      {/* Hero Section */}
      <div className="relative h-[200px] md:h-[350px]">
        <img
          src="assets/photobooth/hero-photobooth-dmv-date-saver-co.jpg"
          alt="Sunflower in the middle of a picnic table with table setting by date saver co"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-10 flex w-full">
          <h1 className="text-white font-the-seasons text-md md:text-4xl md:text-left text-center flex items-end pl-5 pb-16 md:items-center md:pl-16 md:pt-44">
              Glam Photobooth – When the moment feels right, preserve it.
          </h1>
        </div>
      </div>

      {/* What's Included Section */}
      <div className="py-8 px-4 md:py-12 md:px-16 lg:px-20 mx-auto max-w-[85%] md:flex md:items-center">
        {/* Left Column - Text Section */}
        <div className="md:max-w-md md:mx-auto">
          <h3 className="text-3xl md:text-4xl font-bold font-the-seasons mb-6 text-center">
          What is the Glam Photobooth?
          </h3>
          <p className="text-md leading-relaxed font-columbia-serial text-[#231f20] mb-6 text-justify md:max-w-[80%] justify-center md:mx-auto">
            A sleek, interactive mirror booth with a hidden DSLR for high-quality shots. Capture memories when it feels right with instant WiFi 
            sharing and premium, on-the-spot prints. Every candid shot is a touch of elegance, creating keepsakes that last a lifetime.  
          </p>
          <div className='items-center justify-center mx-auto max-w-[40%] md:pl-6 pb-11 md:pb-0'>
            <button onClick={scrollTo} className="font-the-seasons px-5 py-3 bg-transparent border border-black text-black font-semibold hover:bg-[#231f20] hover:text-white transition duration-300 rounded">
              Book Now
            </button>
          </div>
        </div>

        {/* Right Column - Image Section */}
        <div className="md:w-[60%] md:mt-8">
          <img
            src="assets/photobooth/PRICING-Glam-photobooth.webp"
            alt="Event Content Creator Packages"
            className="w-full h-auto object-cover shadow-lg rounded-lg cursor-pointer drop-shadow-lg shadow-red-950 transition duration-300"
            onClick={() => openModal("assets/photobooth/PRICING-Glam-photobooth.webp")} // Open modal on click
          />
        </div>
      </div>

      {/* Modal for Enlarged Image */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
          <div className="relative">
            <img
              src={selectedImage}
              alt="Enlarged Event Content Creator"
              className="max-w-[90vw] max-h-[90vh] rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}

      {/* Video Carousel */}
      <div className='mx-auto md:max-w-6xl max-w-[90%]  md:mt-20 mt-11'>
      <Carousel>
            <div className="h-72 md:h-[550px] rounded-3xl overflow-hidden">
              <video
                src="/assets/photobooth/main-retro-interactive-mirror-glam-photobooth-date-saver-co.webm"
                className="object-cover w-full h-[85%] md:h-[500px]"
                controls
                loop
                playsInline 
              />
            </div>
            <div className="h-72 md:h-[550px] rounded-3xl overflow-hidden">
              <video
                src="/assets/photobooth/corporate-event-photobooth-DMV-date-saver-co.webm"
                className="object-cover w-full h-[85%] md:h-[500px]"
                controls
                loop
                playsInline 
              />
            </div>
            <div className="h-72 md:h-[550px] rounded-3xl overflow-hidden">
              <video
                src="/assets/photobooth/retro-glam-photobooth-greenscreen-DMV-date-saver-co.webm"
                className="object-cover w-full h-[85%] md:h-[500px]"
                controls
                loop
                playsInline 
              />
            </div>
            <div className="h-72 md:h-[550px] rounded-3xl overflow-hidden">
              <video
                src="/assets/photobooth/retro-photobooth-DMV-at-bat-mitzvah-date-saver-co.webm"
                className="object-cover w-full h-[85%] md:h-[500px]"
                controls
                loop
                playsInline 
              />
            </div>
          </Carousel>
      </div>



      {/* Get Started Section */}
      <div className="py-10 px-4 md:pt-28 md:pb-16 md:mx-auto md:max-w-6xl text-justify md-px-11">
        <h2 className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-8 md:mb-12 text-center font-bold">
          GET STARTED
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-11 gap-7">
          {/* Step 1 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-semibold font-the-seasons mb-4 text-center">
              Step 1 <span className='font-sans font-extralight'>|</span> Select a Package
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              Select your <span className='font-bold'>photobooth package</span> and lock in your <span className='font-bold'>2025</span> date using our quick scheduling tool (it takes less than 5 minutes!)
            </p>
          </div>
          {/* Step 2 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4 text-center">
              Step 2 <span className='font-sans font-extralight'>|</span> Secure Your Date
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              <span className='font-bold'>Submit your retainer</span> during checkout to officially reserve your spot.
            </p>
          </div>
          {/* Step 3 */}
          <div className='text-[#231f20]'>
            <h3 className="text-lg md:text-2xl font-bold font-the-seasons mb-4 text-center">
              Step 3 <span className='font-sans font-extralight'>|</span> Enjoy
            </h3>
            <p className="text-sm md:text-[98%] font-columbia-serial leading-relaxed">
              <span className='font-bold'>Relax</span> and let us bring the photobooth magic to your event!
            </p>
          </div>
        </div>
      </div>

      {/* Acuity Scheduling Section */}
      <div className="py-5 md:max-w-[80%] md:pb-1 md:pt-11 md:mx-auto text-left">
        <h2 id='bookhere' className="text-xl md:text-4xl text-[#231f20] font-the-seasons mb-4 md:mb-14 text-center font-bold">
          BOOK HERE
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-0">
          {/* Text and Image Section */}
          <div className="text-[#231f20]">
            <h3 className="text-lg md:text-3xl font-bold font-the-seasons mb-4 text-center">
              Photobooth
            </h3>
            <p className='text-center mx-auto max-w-xl'>Please reach out to us by phone, email or the form below if you have any questions before booking. </p>
            <div className='w-[80%] mx-auto h-auto object-cover mt-6 rounded-xl shadow-lg'>
            <Carousel>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/glam-photobooth -couple-laughing-while-interacting-with-screen-datesaverco-DMV-1.webp"
                alt="Picnic wide angle"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/glam-photobooth-DMV-date-saver-co.webp"
                alt="Couple smiling and admiring sparkling wine while sitting by a low picnic table"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/glam-photobooth-groom-lifts-bride-datesaverco-DMV-1.webp"
                alt="Couple belly laughing on knit lounge chairs"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/mirror-photobooth-wedding-DMV-date-saver-co.webp"
                alt="Couple smiling admiring sparkling wine"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/carousel-picsRetro-Mirror-Booth-Event-Impression-At-a-wedding-10.webp"
                alt="Couple sitting next to each other in a picnic table, facing each other while enjoying."
                className="object-cover w-full h-full"
              />
            </div>
            <div className="h-60 md:h-[400px] rounded-3xl overflow-hidden shadow-lg">
              <img
                src="/assets/photobooth/carousel-pics/wedding-photobooth-DMV-date-saver-co.webp"
                alt="Couple sitting next to each other in a picnic table, facing each other while enjoying."
                className="object-cover w-full h-full"
              />
            </div>
          </Carousel>
          </div>

            {/* Dropdown Section */}
            <div className='py-7'>
              <h4
                className="underline text-xl md:text-3xl font-bold font-the-seasons cursor-pointer flex items-center pl-5 md:pl-9 justify-center"
                onClick={toggleDropdown}
              >
                Important Notes:&nbsp;
                <FiChevronDown
                  className={`transition-transform transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                  size={25}
                />
              </h4>

               {/* Dropdown content */}
               <div
                className={`md:pl-0 overflow-hidden transition-all duration-500 ${isDropdownOpen ? 'max-h-screen' : 'max-h-0'}`}
              >
                <ul className='list-disc mx-7 md:mx-auto md:max-w-xl pt-5 space-y-3 text-justify text-sm'>
                  <li>
                    <span className='underline font-bold'>Rain / Strong Winds:</span> To keep our equipment safe, we may need to withhold items 
                    like tech, tables, and pillows in certain weather conditions. If the customer chooses to continue, they will be responsible for any damages. 
                    For safety, rain or strong winds may prevent us from setting up specific items. Thank you for your understanding!
                  </li>
                  <li>
                    <span className='underline font-bold'>Set-up Time:</span> Allow us about 1 hour to set-up before the event, time subject to vary based on add-ons.
                  </li>
                  <li>
                    <span className='underline font-bold'>Retainer Fee: </span> To reserve your special date, we require a non-refundable retainer fee of 50% of the total cost. 
                    This fee secures your date and allows us to plan ahead, ensuring everything runs smoothly.
                  </li>
                  <li>
                    <span className='underline font-bold'>Cancellations:</span> Cancellations within 48 hours of the event <span className='font-bold'>OR</span> bookings made less than 2 days’ notice are not eligible for a refund.

                  </li>
                  <li>
                    <span className='underline font-bold'>Rescheduling:</span> Unexpected things happen! Let us know if you need to reschedule, 
                    and we’ll work with you to find a new time, subject to availability.
                  </li>
                  <li>
                    <span className='underline font-bold'>Weather Insurance ($95):</span>  Add weather insurance for $95 to your booking for
                     peace of mind. If bad weather strikes, you can cancel up to the last minute and get a full refund, including the retainer. 
                     You can select this option at checkout. (Non-refundable).
                  </li>
                  <li>
                    <span className='underline font-bold'>Location access:</span> It is the client’s responsibility to ensure that <span className='font-bold'>private</span> locations
                     are accessible for setup and takedown at the agreed-upon times. 
                  </li>
                  <li>
                    <span className='underline font-bold'>Last minute time extension:</span> If there are delays, and you would wish to extend your time, you may do so at the 
                    event (just give us a call or approach any of our team members). Appointment time extensions are subject to availability and charges specific to the service.
                  </li>
                </ul>
              </div>
            </div>
          </div>


          {/* Acuity Scheduling iframe */}
          <div className="mb-44 w-full overflow-hidden rounded-xl">
            <p className='text-center md:pb-9'> Please scroll down to see more information. </p>
            <iframe
              src="https://app.acuityscheduling.com/schedule.php?owner=33469695&owner=33469695&appointmentType=category:Mirror%20Photobooth"
              title="Schedule Appointment"
              className="w-full h-[1800px] md:h-[900px] rounded-xl"
              style={{ border: 'none' }}
            >
              <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Photobooth;
