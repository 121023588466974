import React from 'react';
import ReactDOM from 'react-dom/client'; // Import the new createRoot API from React 18
import App from './App';  // Import the App component

// Get the root DOM node
const rootElement = document.getElementById('root') as HTMLElement;

// Create a root and render the app into it
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
