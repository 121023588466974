import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const NavBar: React.FC = () => {
  const [nav, setNav] = useState(false);
  const [color, setColor] = useState("transparent");
  const [textColor, setTextColor] = useState("#ffffff");
  const [logo] = useState("/assets/whitelogo.png");

  const location = useLocation();
  const navigate = useNavigate(); // Add the navigate hook to handle routing
  const [copied, setCopied] = useState<boolean>(false);

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 50) {
        setColor("#131313"); // The background color after scrolling
        setTextColor("#404040"); // Text color after scrolling
      } else {
        setColor("transparent"); // Transparent background before scrolling
        setTextColor("#ffffff"); // White text before scrolling
      }
    };
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setNav(false); // Close the mobile menu after clicking
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNav(false); // Close the mobile menu after clicking
  };

  useEffect(() => {
    // Detect if there is a hash in the URL and scroll to that section
    if (location.hash) {
      const sectionId = location.hash.replace('#', '');
      scrollToSection(sectionId);
    }
  }, [location]);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('datesaverco@gmail.com')
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error('Could not copy text: ', err));
  };

  // This function handles navigation and closes the mobile menu
  const handleMobileNavClick = (targetId: string, path: string) => {
    if (location.pathname !== path) {
      // Navigate to the correct page first, then scroll to the section after a short delay
      navigate(path);
      setNav(false);
      setTimeout(() => scrollToSection(targetId), 300);
    } else {
      // Scroll to the section on the current page
      scrollToSection(targetId);
    }
  };

  return (
    <div
      style={{ backgroundColor: color }}
      className="fixed left-0 top-0 w-full z-50 ease-in duration-300 hover:bg-[#231f20] hover:bg-opacity-100"
    >
      <div className="relative w-full flex justify-between items-center p-3 text-white font-columbia-serial">
        {/* Save My Date on the left - Hidden on mobile */}
        <div className="flex items-center lg:block hidden">
          <a
            href="#services"
            onClick={() => scrollToSection("contact")}
            className="text-lg tracking-widest rounded-lg border border-white pb-1 pt-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] transition border-solid font-the-seasons"
          >
            BOOK NOW
          </a>
        </div>

        {/* Socials and Email Copy in the same flex container for desktop */}
        <div className="hidden lg:flex items-center space-x-3">
          {/* Padding added between the Book Now button and the Social Icons */}
          <div className="pl-10 flex items-center space-x-4">
            <a href="https://instagram.com/datesaverco" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://facebook.com/profile.php?id=61561750892261" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://www.tiktok.com/@datesaverco" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://youtube.com/%40datesaverco?si=sD66jorEstXtFCIR" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <button onClick={handleCopyEmail} className="focus:outline-none">
              <AiOutlineMail className="w-6 h-6 hover:text-[#ddc6a3]" />
            </button>
            {copied && (
              <p className="text-white text-sm italic pl-2">Email copied to clipboard!</p>
            )}
          </div>
        </div>

        {/* Logo in the center, adjusted for mobile */}
        <div className="flex justify-center items-center w-full lg:w-auto lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 translate-x-5">
          <a href="#top" onClick={scrollToTop}>
            <img
              src={logo}
              alt="Date Saver Co Logo"
              width={36}
              height={36}
              className="h-9 w-auto"
            />
          </a>
        </div>

        {/* Right-aligned nav links */}
        <ul className="hidden lg:flex flex-wrap space-x-4 xl:space-x-8 ml-auto pr-4 lg:pr-12 rounded-xl font-the-seasons text-white text-sm tracking-widest">
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9]  transition border-solid rounded-xl">
            <a href="#aboutus" onClick={() => scrollToSection("aboutus")}>
              ABOUT US
            </a>
          </li>
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] rounded-t-xl transition border-solid relative group">
            <a href="#services" className="relative z-10">
              SERVICES
            </a>

            {/* Dropdown Menu for Services */}
            <div className="rounded-xl absolute left-0 mt-2 w-40 bg-[#f4f1e9] text-[#342b29] text-xs shadow-lg opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto pointer-events-none group-hover:translate-y-0 transform translate-y-2 transition-all duration-300">
              <a
                href="fyp"
                onClick={() => scrollToSection("FYP")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                For You Picnic
              </a>
              <a
                href="lazyboy"
                onClick={() => scrollToSection("Lazy Boy")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Tablescape
              </a>
              <a
                href="contentcreator"
                onClick={() => scrollToSection("Content Creator")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Content Creator
              </a>
              <a
                href="photobooth"
                onClick={() => scrollToSection("Glam Photobooth")}
                className="block py-2 px-6 text-sm hover:text-[#342b29] hover:bg-[#c1b4a7] bg-[#f4f1e9] transition border-solid"
              >
                Glam Photobooth
              </a>
            </div>
          </li>
          <Link to="/faq" onClick={scrollToTop}>
            <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] rounded-xl transition border-solid">
              FAQ
            </li>
          </Link>
          <li className="py-2 px-4 hover:text-[#342b29] hover:bg-[#f4f1e9] rounded-xl transition border-solid">
            <a href="#contact" onClick={() => scrollToSection("contact")}>
              CONTACT
            </a>
          </li>
        </ul>

        {/* Mobile Button with added padding */}
        <div onClick={handleNav} className="block lg:hidden z-50 pr-4">
          {nav ? (
            <AiOutlineClose size={20} style={{ color: textColor }} />
          ) : (
            <AiOutlineMenu size={20} style={{ color: textColor }} />
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={
          nav
            ? "lg:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-[#231f20] text-center ease-in duration-300"
            : "lg:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-[#231f20] text-center ease-in duration-300"
        }
      >
        <ul className="font-the-seasons text-[#fff4eb] space-y-7 text-lg justify-center object-center">
          <li
            onClick={() => handleMobileNavClick("aboutus", "/")}
            className="pt-0"
          >
            About Us
          </li>
          <li
            onClick={() => handleMobileNavClick("services", "/")}
            className="pt-0"
          >
            Services
          </li>
          <li 
            onClick={() => handleMobileNavClick("", "/faq")} 
            className="pt-0">
            FAQ
          </li>
          <li
            onClick={() => handleMobileNavClick("contact", "/")}
            className="pt-0"
          >
            Contact
          </li>
          <li
            onClick={() => handleMobileNavClick("services", "/")}
            className="p-2 hover:text-white border border-white rounded-xl font-the-seasons"
          >
            BOOK NOW
          </li>

          {/* Social Icons inside the hamburger menu for mobile */}
          <div className="flex justify-center space-x-4 pt-4">
            <a href="https://instagram.com/datesaverco" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://facebook.com/profile.php?id=61561750892261" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://www.tiktok.com/@datesaverco" target="_blank" rel="noopener noreferrer">
              <FaTiktok className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <a href="https://youtube.com/%40datesaverco?si=sD66jorEstXtFCIR" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="w-6 h-6 hover:text-[#ddc6a3]" />
            </a>
            <button onClick={handleCopyEmail} className="focus:outline-none">
              <AiOutlineMail className="w-6 h-6 hover:text-[#ddc6a3]" />
            </button>
          </div>
          {copied && (
            <p className="text-white text-sm italic font-columbia-serial">Email copied to clipboard!</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
